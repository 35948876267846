// title: Resources for MTG Cube and More - Lucky Paper
// frameCardName: Supply Runners
// frameCardSet: JMP
// lastUpdatedDate: 2023-06-26

import * as styles from './index.module.scss'

import React from 'react'
import { Link } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

import NewsletterSignUp from 'components/newsletter/NewsletterSignUp'

import CubeMapBackground from './cube-map-background.png'
import EDHMapBackground from './edh-map-background.png'

interface Props {
  pageContext: any
}

const ResourcesPage: React.FC<Props> = (props) => {
  return (
    <Layout {...props.pageContext} accentColor="#4D8EB0">
      <article className={styles.container}>
        <h1 className={styles.heading}>
          <Typography.PrimaryHeading>
            Resources for Cube and More
          </Typography.PrimaryHeading>
        </h1>

        <div className={styles.resources}>
          <Link to="/resources/cube-map/" className={styles.resource}>
            <img
              src={CubeMapBackground}
              className={styles.resourceBackground}
            />
            <h2 className={styles.resourceHeading}>Cube Map</h2>
            <p className={styles.resourceDescription}>
              Discover just how diverse the Cube format can be, themes you never
              expected, and where your own cube fits.
            </p>
          </Link>

          <Link to="/resources/commander-map/" className={styles.resource}>
            <img src={EDHMapBackground} className={styles.resourceBackground} />
            <h2 className={styles.resourceHeading}>Commander Map</h2>
            <p className={styles.resourceDescription}>
              Explore trends and patterns in an interactive visualization of
              over two and a half million Commander decks organized by the cards
              they contain.
            </p>
          </Link>

          <Link to="/resources/formats/" className={styles.resource}>
            <h2 className={styles.resourceHeading}>Cube Draft Formats</h2>
            <p className={styles.resourceDescription}>
              A guide to the many ways to draft and play Cube. Find new ways to
              play and accommodate any size playgroup.
            </p>
          </Link>

          <Link to="/set-prospectives/" className={styles.resource}>
            <h2 className={styles.resourceHeading}>
              Community Cube Set Reviews
            </h2>
            <p className={styles.resourceDescription}>
              First impressions from the Cube community and editor commentary on
              each new set.
            </p>
          </Link>

          <Link to="/events/" className={styles.resource}>
            <h2 className={styles.resourceHeading}>Upcoming Cube Events</h2>
            <p className={styles.resourceDescription}>
              Want to play Cube in paper at a location near you? Check out our
              list of upcoming Cube events around the world.
            </p>
          </Link>
        </div>

        <div className={styles.moreTools}>
          <div>
            <Typography.TertiaryHeading>
              Practical Articles
            </Typography.TertiaryHeading>
            <ul className={styles.moreToolsList}>
              <li className={styles.moreToolsItem}>
                <Link to="/what-is-a-cube/">
                  <div className={styles.moreToolsTitle}>What is Cube</div>
                  <div>A brief introduction for the curious</div>
                </Link>
              </li>

              <li className={styles.moreToolsItem}>
                <Link to="/articles/frequently-asked-questions-about-cube/">
                  <div className={styles.moreToolsTitle}>
                    Frequently Asked Questions about Cube
                  </div>
                  <div>
                    Answers to many of the common questions about designing,
                    building, and playing Cube.
                  </div>
                </Link>
              </li>

              <li className={styles.moreToolsItem}>
                <Link to="/articles/the-first-four-questions-cube-designers-should-ask/">
                  <div className={styles.moreToolsTitle}>
                    The First Four Questions Cube Designers Should Ask
                  </div>
                  <div>
                    Critical components for framing your Cube to inform design
                    decisions
                  </div>
                </Link>
              </li>

              <li className={styles.moreToolsItem}>
                <Link to="/articles/building-a-cube-from-a-collection/">
                  <div className={styles.moreToolsTitle}>
                    Building a Cube from a Collection
                  </div>
                  <div>
                    Starting with what you have is one of the best ways to build
                    your first Cube which will be naturally imbued with your own
                    magical tastes.
                  </div>
                </Link>
              </li>

              <li className={styles.moreToolsItem}>
                <Link to="/articles/searching-with-scryfall-magic-at-your-fingertips/">
                  <div className={styles.moreToolsTitle}>
                    Searching with Scryfall: Magic at your Fingertips
                  </div>
                  <div>
                    Find just the right cards for your needs with powerful,
                    flexible, and fast search tools
                  </div>
                </Link>
              </li>

              <li className={styles.moreToolsItem}>
                <Link to="/articles/regular-expressions/">
                  <div className={styles.moreToolsTitle}>
                    Regular Expressions: Powerful Card Searching
                  </div>
                  <div>
                    Level up your spell-finding wizardry with powerful text
                    pattern matching
                  </div>
                </Link>
              </li>

              <li className={styles.moreToolsItem}>
                <Link to="/articles/a-guide-to-battle-box/">
                  <div className={styles.moreToolsTitle}>
                    A Guide to Battle Box
                  </div>
                  <div>Get started with a fun, portable, no-setup format</div>
                </Link>
              </li>

              <li className={styles.moreToolsItem}>
                <Link to="/articles/set-cubes-and-more/">
                  <div className={styles.moreToolsTitle}>Set Cubes & More</div>
                  <div>
                    Recapture a favorite draft format, bootstrap a Cube, or
                    celebrate a beloved plane
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <Typography.TertiaryHeading>More Tools</Typography.TertiaryHeading>
            <ul className={styles.moreToolsList}>
              <li className={styles.moreToolsItem}>
                <a href="/resources/formats/rotisserie/">
                  <div className={styles.moreToolsTitle}>
                    Rotisserie Draft Spreadsheet Templates
                  </div>
                  <div>
                    Google Sheets templates for hosting asynchronous Rotisserie
                    drafts
                  </div>
                </a>
              </li>
              <li className={styles.moreToolsItem}>
                <Link to="/resources/set-cube-builder/">
                  <div className={styles.moreToolsTitle}>Set Cube Builder</div>
                  <div>
                    Generate a Cube list for any set and any rarity ratio ready
                    to import to your favorite Cube manager
                  </div>
                </Link>
              </li>

              <li className={styles.moreToolsItem}>
                <Link to="/resources/scratchpad/">
                  <div className={styles.moreToolsTitle}>MTG Scratchpad</div>
                  <div>
                    Build, view, and annotate card lists - built for podcasting
                    reference
                  </div>
                </Link>
              </li>
              <li className={styles.moreToolsItem}>
                <Link to="/resources/list-formatter/">
                  <div className={styles.moreToolsTitle}>
                    MTG &ldquo;List Formatter&rdquo;
                  </div>
                  <div>
                    Transform and augment lists of cards with data from Scryfall
                  </div>
                </Link>
              </li>
              <li className={styles.moreToolsItem}>
                <a href="https://github.com/ahmattox/cube-cobra-google-sheets">
                  <div className={styles.moreToolsTitle}>
                    Cube Cobra + Google Sheets Integration
                  </div>
                  <div>
                    Copy and paste this script into a Google Sheet to add
                    functions for loading Cubes.
                  </div>
                </a>
              </li>
              <li className={styles.moreToolsItem}>
                <a href="https://github.com/ahmattox/mtg-scripting-toolkit">
                  <div className={styles.moreToolsTitle}>
                    MTG Scripting Toolkit
                  </div>
                  <div>
                    TypeScript Scripting utilities for working with Magic card
                    data on Github
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <Typography.Divider />
      </article>
      <NewsletterSignUp />
    </Layout>
  )
}

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Resources for MTG Cube and More - Lucky Paper',
        description:
          'Resources from Lucky Paper for Magic: the Gathering Cube and more. Explore interactive maps of Commander and Cube. Find guides for ways to play and resources for managing cards.',
      }}
    />
  )
}

export default ResourcesPage
